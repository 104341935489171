import "./App.css";
import Comentarios from "./components/Comentarios";
import Display from "./components/Display";

function App() {
  return (
    <div className="App">
      <Comentarios />
      <Display />
    </div>
  );
}

export default App;
