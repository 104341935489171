import { useSelector } from "react-redux";
import "./styles.css";

const Display = () => {
  const result = useSelector((store) => store.user);

  return (
    <div>
      Usuario : {result.name}
      {result.comments.map((coments, index) => (
        <div className="comentario" key={index}>
          {coments}
        </div>
      ))}
    </div>
  );
};

export default Display;
