import "./styles.css";

// 1 - importe o useDispatch
import { useState } from "react";
import { useDispatch } from "react-redux";

// 2 - importe o seu thunk
import { addCommentThunk } from "../../store/modules/user/thunks";

// 3 - dentro do seu componente faça o dispatch do thunk, passando o comentário
const Comentarios = () => {
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");

  const handleClick = () => {
    if (userInput === "") {
      alert("Escreva um comentario :)");
    } else {
      dispatch(addCommentThunk(userInput));
      setUserInput("");
    }
  };
  return (
    <>
      <input
        type="text"
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
      />
      <button onClick={() => handleClick()}>New comment</button>
    </>
  );
};
export default Comentarios;
